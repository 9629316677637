




























import Vue from 'vue'
import {LolStoreModule} from "@/store";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

export default Vue.extend({
    components: { BaseButtonRounded },
    data() {
        return {
            keyInput: "",
            loaded: false
        }
    },
    methods: {
        changeKey(): void {
            if (!this.keyInput || this.keyInput === "") return;
            const key = this.keyInput;
            console.log(key);
            this.loaded = true;
            LolStoreModule.changeApiKey(key)
            this.keyInput = "";
            setTimeout(()=>{this.loaded=false}, 1500);
        }
    }
})
