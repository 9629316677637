






import {Component} from "vue-property-decorator";
import Vue from "vue";
import KeyInput from "@/components/KeyInput.vue";

@Component({
  components: {KeyInput}
})
export default class UserSettingsApiKeyPage extends Vue {

}
